import React from 'react';
import './Header.css';


const Header = () => {
    return (
        <div className="banner">
            <div className="container text-center">
                <div className="">
                    <h1 className="">Best Food Waiting For Your Belly</h1>
                </div>
            </div>
        </div>
    );
};

export default Header;